/* You can add global styles to this file, and also import other style files */
// @import '@angular/cdk/overlay-prebuilt.css';
// @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=fallback');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,400,500,600,700&display=fallback');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=auto');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=fallback');


/* @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/prebuilt-themes/purple-green.css'; */

@import "app/theme/styles/base";
@import "app/theme/styles/theme";

@import "ngx-smart-modal/ngx-smart-modal";
@import "app/app.component";

@import "@ng-select/ng-select/themes/material.theme.css";

@import "animate.css/animate.min";

@import 'ngx-sharebuttons/themes/default';
/* @import 'ngx-sharebuttons/themes/default'; */

/* .mat-drawer-content{
	background-image: url('./assets/images/others/fondo-imagenes.png');
	background-attachment: fixed;
} */

/* html{
	scroll-behavior: smooth;
} */
*{
	font-display: swap;
}
.modal-backdrop {
    // display:none;
	overflow: auto;
}
.b24-widget-button-wrapper.b24-widget-button-position-bottom-right, .b24-widget-button-wrapper.b24-widget-button-position-bottom-middle, .b24-widget-button-wrapper.b24-widget-button-position-bottom-left{
	bottom: 5px;
	right: 5px;
}
.app.iegrupo .mat-toolbar .mat-form-field-underline{
	display: none;
}
// swiper
.swiper-button-prev:after, .swiper-button-next:after{
	font-size: 20px !important;
	color: #000000;
	font-weight: 900;
}
.swiper-button-next, .swiper-button-prev{
	width: 40px !important;
    height: 40px !important;
    background: #f8e00c;
    border-radius: 50%;
    box-shadow: 0px 0px 5px #000;
    color: #000000;
}
@import "swiper/css";
@import "swiper/css/bundle";

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}


.registro-modal {
	// padding-bottom: 10px;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
	}
	#registro-modal-formulario{
		background-color: #ffffff;
		margin: 0 0 10px 0;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
@keyframes example {
	from {
		right: -100%;
	}
	to {
		right: 0;
	}
}
@keyframes salir {
	from {
		right: 5px !important;
	}
	to {
		right: -50px !important;
	}
}

.hoja-lateral {
	position: absolute !important;
	right: 5px !important;
    height: auto !important;
    width: 600px !important;
	bottom: 80px !important;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
		background-color: #ffffff !important;
		animation-name: example !important;
		animation-duration: 2s !important;
		box-shadow: 2px 2px 2px #000000;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
.cerrar-hoja-lateral {
	.mat-dialog-container{
		animation-name: salir !important;
		animation-duration: 2s !important;
		box-shadow: 2px 2px 2px #000000;
	}
	::-webkit-scrollbar {
		display: none;
	}
}

//modal 
.modal-edit-art {
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
	}
	form {
		width: 100%;
		background-color: #ffffff;
		margin: 0 0 10px 20px;
	}
	::-webkit-scrollbar {
		display: none;
	}
}

@media only screen and (max-width: 600px){
	.cc-window {
		.cc-message {
			font-size: 8px;
			line-height: normal;
		}
		.cc-link {
			font-size: 12px;
		}
	}
}
.cdk-overlay-backdrop {
	position: fixed; 
	width: 100vw !important;
	height: 100vh !important;
}

.pagos-modal {
	// padding-bottom: 10px;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
		background: none !important;
		box-shadow: none !important;
	}
	#pagos-modal-formulario{
		background-color: #ffffff;
		margin: 0 0 10px 0;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
/* 
.cdk-global-overlay-wrapper{
	pointer-events:auto!important;//beabletoscrollevent
	overflow-y:scroll!important;
	align-items:start!important;//notcenterthedialog
	z-index:auto!important;//removez-index
}
.cdk-overlay-pane{
	margin:1rem;//entercodehere
	z-index:1002; // over the overlay
 } */
 .cdk-global-scrollblock{
	z-index: auto;
 }

 app-home, app-home-bogota, app-home-bucaramanga, app-home-cali, app-home-cartagena, app-home-cucuta, app-home-ibague, app-home-manizales,
 app-home-medellin, app-home-monteria, app-home-mosquera, app-home-neiva, app-home-pereira, app-home-villavicencio{
	display: block;
	/* box-shadow: 1px 1px 5px #666666; */
	border-radius: 0 0 5px 5px;	
 }
.app.iegrupo .mat-toolbar.mat-primary {
    /* background: #f8e00c; */
    background: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

.error-snack {
	background-color: #DC3544 !important;
}
.componente-buscador
.mat-form-field-label{
	height: 50px !important; 
	font-size: 24px !important;
}

/* 
::-webkit-scrollbar{
	width: 20px;
	display: none;
}


::-webkit-scrollbar-thumb{
	background-image: url('./assets/images/others/ie-logo-mant.png');
	background-size: contain;
	background-repeat: repeat-y;
	border-radius: 3em;
}
::-webkit-scrollbar-thumb:hover{
	background-image: url('./assets/images/others/ie-logo-mant.png');
	background-size: contain;
	background-repeat: repeat-y;
}
 */

.custom-tooltip {
	background-color: #ffe100;
	color: black;
	padding: 16px;
	border-radius: 8px;
}

@media screen and (max-width: 400px){
	app-home{
		position: relative;
		/* background-color: red; */
		margin-top: 60px;
	}
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';
  }




  ///////////////////////////////// STYLES PHONE INPUT ///////////////////////////
/* You can add global styles to this file, and also import other style files */
body {
	font-family: Roboto, Helvetica, Arial, sans-serif;
}

.wrapper {
	margin-bottom: 20px;
}

/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #c7cace;
	border-radius: 4px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
/// END STYLES PHONE INPUT 